<template>
  <div class="flex align-center page">
    <div class="flex justify-center logo">
      <img src="https://exh37.oss-cn-shenzhen.aliyuncs.com/test/config/9101/3605e52d-67e0-4c95-8aeb-55f3d7dabe74_zh.jpg" style="width: 180px;" >
    </div>
    <div class="container">
      <div class="title">现场查验系统</div>
      <div class="content">
        <van-field v-model="phone" ref="phone" type="tel" maxlength="11" placeholder="请输入手机号" class="phone font13 radius" />
        <div class="flex justify-between input-box">
        	<van-field v-model="codeVal" ref="codeVal" type="tel" maxlength="6" center placeholder="验证码" class="code font13 radius" />
        	<van-button v-if="!isSend" type="primary" color="#048DAD" class="code-btn font12 radius flex align-center justify-center" @touchstart="sendCode">
        		<span class="letter-spacing">获取验证码</span><br/>
        	</van-button>
        	<van-button v-else type="primary" color="048DAD" disabled class="code-btn font13 radius">
        		<van-count-down ref="countDown" class="text-white" :time="time" format="ss s" @finish="isSend = false" />
        	</van-button>
        </div>
        <van-button type="primary" block color="#048DAD" class="radius letter-spacing next-btn" @touchstart="login">登 录</van-button>
      </div>
    </div>
    
    <div class="footer">
    	<bottom :mode="0" :level="0" color="#ffffff"></bottom>
    </div>

  </div>
</template>

<script>
  import bottom from '@/view/process/components/bottom.vue';
  import { Field, CountDown, Button } from 'vant';
  import { getCodeByPhone, checkLogin } from '@/api/index.js';
  export default {
    components: {
      [Field.name]: Field,
      [CountDown.name]: CountDown,
      [Button.name]: Button,
      bottom,
    },
    data() {
      return {
        phone: '',
        codeVal: '', // 168168
        isSend: false, // 是否已经发送验证码
        time: 60 * 1000, // 倒计时时长
      }
    },
    created() {
      document.title = this.$router.history.current.meta.title;
    },
    methods: {
      // 设置输入栏焦点
      setFocus(ref) {
        setTimeout(()=> {
          this.$refs[ref].focus();
        }, 300)
      },
      // 校验手机号
      validatePhone() {
      	if(!this.phone) {
      		this.$toast('请输入手机号');
          this.setFocus('phone'); // 设置输入栏焦点
      		return true;
      	}
      	if (!/1\d{10}/.test(this.phone)) {
      		this.$toast('请输入正确的手机号');
      		this.setFocus('phone'); // 设置输入栏焦点
      		return true;
      	}
      	return false
      },
      // 校验验证码
      validateCode() {
      	if (!this.codeVal) {
      		this.$toast('请输入验证码');
          this.setFocus('codeVal'); // 设置输入栏焦点
      		return true;
      	}
      	return false
      },
      // 发送验证码
      sendCode() {
      	if (this.validatePhone()) return;
      	
      	this.isSend = true;
      	let params = {
      		phone: this.phone
      	};
      	getCodeByPhone(params).then( () => {
      		this.$toast('验证码已发送');
      		this.$refs.countDown.start(); // 开始倒计时
      	})
      },
      login() {
        // 校验所有填写项是否合格
        if (this.validatePhone() || this.validateCode()) return;
        
        this.$toast.loading({
        	forbidClick: true,
        	duration: 0,
        });
        
        let params = {
          phone: this.phone,
          code: this.codeVal,
        };
        checkLogin(params).then(res => {
          this.$toast.clear();
          if(res.code == 200) {
            this.$router.push({
              path: '/info',
              query: {
                uid: res.data
              }
            })
          }
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .page {
    background-color: #002537;
    background-image: url(https://exh37.oss-cn-shenzhen.aliyuncs.com/dev/config/6201/feb370df-c082-4d34-a0a8-eca71a1c80d4_en.jpg);
    background-size: cover;
  }
  
  .logo {
    margin-top: 9vh;
  }
  .container {
    
    .title {
      color: #FFFFFF;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 6vh;
    }
    
    .content {
    	width: 255px;
    	margin: 0 auto ;
    	
    	.phone {
    		display: flex;
    		align-items: center;
    		height: 40px;
    		margin-bottom: 3vh;
    	}
    
    	.code {
    		height: 40px;
    		margin-right: 14px;
    	}
    	
    	/deep/ .van-button--normal {
    		padding: 0;
    	}
    
    	.code-btn {
    		height: 40px;
    		width: 180px;
    	}
    	
    	.input-box {
    		margin-bottom: 3vh;
    	}
    	
    	.next-btn {
        font-size: 16px;
    		height: 40px;
    		margin-bottom: 6vh;
    	}
    	
    }
    
  }
  
</style>
